.lazyImageContainer {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.lazyImage {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;

    &.thumb {
        opacity: 1;
        filter: blur(10px);
        transition: opacity 0.2s ease-in-out;
        position: absolute;

        &.isLoaded {
            opacity: 0;
        }
    }

    &.isLoaded {
        transition: opacity 0.2s ease-in-out;
        opacity: 1;
    }
}
