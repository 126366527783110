.svgIcon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5em;
    vertical-align: middle;
    pointer-events: none;
}

.btn {
    display: inline-block;
    box-shadow: 0 0 0 transparent;
    height: 2em;
    font-size: inherit;
    font-family: inherit;
    background-color: var(--color-main-lighter);
    line-height: 2em;
    padding: 0 0.5em;
    margin: 0;
    color: inherit;
    border-radius: 4px;
    border-width: 0;
    vertical-align: baseline;
    text-decoration: none;
    cursor: pointer;
    outline: 0;

    small & {
        font-size: 0.8rem;
    }
    small:has(&) {
        vertical-align: middle;
        display: inline-block;
    }

    &:hover,
    &:active {
        background-color: var(--color-main-light);
        color: inherit;
    }
    &:is(a) {
        text-decoration: none;
    }
    &:is(a):hover {
        text-decoration: none;
    }
}
