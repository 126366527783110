.videoThumbs {
    display: flex;
    overflow: auto;
    width: 100%;
    padding: 0 1rem;
    gap: 0.2em;
}
.videoThumb {
    height: 8em;
    aspect-ratio: 16/9;
    border-radius: 0.4em;
    overflow: hidden;
    flex: none;
    display: inline-block;

    button {
        border-width: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        vertical-align: middle;
        background-color: transparent;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        scale: 1;
        transition: all 0.6s;
    }
    &:hover img {
        scale: 1.1;
    }
}
