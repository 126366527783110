@value listSeason from '../../components/list/list.module.css';
@value listEpisode from '../../components/list/list.module.css';
@value tvCreditsList from '../../components/list/list.module.css';

.badge {
    position: absolute;
    top: 0.5rem;
    right: 0;
    line-height: 1;
    font-size: 0.6em;
    padding: 0.2em 0.5em;
    background-color: white;
    border-radius: 0.5em;
    vertical-align: text-bottom;

    :global(.listSeason) &,
    :global(.listEpisode) & {
        right: 0.5rem;
        top: initial;
        bottom: 0.5rem;
        box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);
    }

    :global(.tvCreditsList) & {
        bottom: 0.5em;
        right: 0.5em;
        top: initial;
    }
}
