.mediaImage {
    max-width: 100%;
    vertical-align: middle;

    .itemImage & {
        border-radius: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        transform: scale(1);
        transition: 1s;
    }
}

.itemJobs,
.itemName,
.itemTitle,
.itemYear {
    font-size: 0.8rem;
    white-space: nowrap;
    position: relative;
    line-height: 1rem;
    height: 1rem;
}
.itemTitle {
    font-weight: bold;
}
.itemDescription {
    margin: 0.4em 0.5em;
    font-weight: normal;
    position: relative;
    overflow: hidden;

    & > * {
        container-type: inline-size;
    }
}

.adultImage {
    filter: blur(15px);
}

.itemImage {
    position: relative;
    overflow: hidden;
    aspect-ratio: 2 / 3;

    .listPerson & {
        aspect-ratio: 1 / 1.2;
        .compare-options & {
            aspect-ratio: 1 / 2;
        }
        .mediaImage {
            border-radius: 0;
        }
    }
}

.singleImage {
    float: right;
    width: 25vw;
    max-width: 200px;
    margin: 0 0 1em 1em;
    position: relative;

    border-radius: 5px;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

    &:hover .overlayBack {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

#episode {
    .singleImage {
        float: none;
        width: 100%;
        max-width: 100%;
        margin: 1em 0;
        .mediaImage {
            width: 100%;
            max-height: 50vh;
            object-fit: contain;
        }
    }
}

.figure {
    &:hover .overlayBack {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.figureOverlay {
    pointer-events: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;

    &.compareSelected,
    .singleImage:hover &,
    .figure:hover & {
        opacity: 1;
    }
}

.marquee {
    /* position:absolute;
   left:0; */
    transition: all 3s linear;
    transform: translateX(0);
    white-space: nowrap;
    display: inline-block;
}

.figureGallery {
    display: flex;
    overflow: auto;
    list-style-type: none;
    padding-left: 0;
    clear: both;
    overflow: auto;
    gap: 0.2em;

    &:is(ul) {
        margin: 0;
    }

    li {
        flex: 0 0 auto;
    }
    img {
        height: 8em;
        width: auto;
        object-fit: cover;
        flex: 0 0 auto;
        cursor: pointer;
    }
    &.posters img {
        aspect-ratio: 2/3;
    }
    &.backdrops img,
    &.logos img {
        aspect-ratio: 1/1;
        object-fit: contain;
    }
    &.logos img {
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
    }
}

.modalFullimage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    border-width: 0;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 2em);
        max-height: calc(100% - 2em);
        object-fit: contain;
        pointer-events: none;
    }
}

.noClick {
    pointer-events: none;
}
.canClick {
    cursor: pointer;
    pointer-events: initial;
}

.profile {
    display: block;
}

.badge {
    right: 0.5rem;
    top: initial;
    bottom: 0.5rem;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);
}
