.formSelect {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    border: 1px solid var(--color-main-light);
    font: inherit;
    font-size: 0.7em;
    display: inline-block;
    position: relative;

    select {
        border-width: 0;
        background-color: transparent;
        outline: 0;
        padding: 0.5em 1em;
        appearance: none;
        max-width: 8em;
        cursor: pointer;
    }
    &::after {
        content: '▼';
        color: var(--color-main-light);
        font-size: 0.8em;
        pointer-events: none;
        position: absolute;
        top: 50%;
        right: 0.3em;
        transform: translateY(-50%);
    }
    @media (max-width: 500px) {
        .section h2 {
            font-size: 1em;
        }
        .formSelect select {
            max-width: 12ch;
        }
    }
}
