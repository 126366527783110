.page {
    flex: 1 1 100%;
    overflow: auto;
    padding-block: 1em;

    max-width: var(--width-standard);
    margin: 1em auto 0;
    box-shadow: 0 0 3px rgb(0 0 0 / 20%);
    border-radius: 5px 5px 0 0;
    background-color: white;
    position: relative;

    &:has(> .headerList) {
        padding-block: 0 1em;
    }
}

.section {
    &:not(:first-child) {
        margin-block: 2em 0;
        clear: both;
    }

    &.compare {
        margin: 1em 0 0;
    }

    &.compare & {
        margin: 0;
    }

    h2 {
        font-size: 1.3em;
    }
}

.navbar {
    background-color: var(--color-main-light);
    position: sticky;
    top: 0;
    z-index: 12;
    ul {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: var(--header-height);
    }
    li {
        position: relative;
    }
    a {
        padding: 0 0.5em;
        display: block;
    }
}

.headerList {
    display: flex;
    justify-content: center;
    line-height: var(--header-height);
    gap: 0.5em;
    border-bottom: 2px solid var(--color-main-lighter);
    a {
        padding: 0 0.5em;
        &:hover {
            background-color: var(--color-main-lighter);
        }
    }
    .active {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: var(--color-main-light);
        }
    }
}

.header {
    height: var(--header-height);
    line-height: var(--header-height);
    background-color: var(--color-main-light);
    /*padding:0 0.5em;*/
    h1 {
        font-size: 1em;
        margin: 0;
        padding: 0 0.5em;
    }
    .container {
        padding: 0 0.5em;
    }
    li {
        position: relative;
    }

    .nav {
        line-height: var(--header-height);
        ul {
            display: flex;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        a {
            display: block;
            padding: 0 0.5em;
            position: relative;
            height: 100%;
            width: 2.5em;
        }
        .svg-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.singleDescription {
    strong {
        display: block;
        font-size: 0.8em;
        color: var(--color-neutral);
        font-weight: normal;
    }
}

.singleProperty {
    margin: 0.5em 0;
    & .title,
    & strong {
        display: flex;
        justify-content: space-between;
        gap: 1em;
        border-width: 0;
        text-align: left;
        cursor: pointer;
    }
    & .title {
        width: 100%;
    }
}

.singleName,
.singleTitle {
    font-size: 1.5em;
    font-weight: bold;
    margin-block-end: 1rem;
}

.container {
    clear: both;

    @media (min-width: 1000px) {
        max-width: var(--width-standard);
        margin-inline: auto;
        padding-inline: 1em;
    }
    @media (max-width: 999px) {
        padding-inline: 0.5em;
    }
    .navbar & {
        padding-inline: 0;
    }
}

.movieCollection {
    position: relative;
}
