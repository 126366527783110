.modal {
    position: fixed;
    inset: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
        position: relative;
        width: calc(100% - 2em);
        aspect-ratio: 16 / 9;
        vertical-align: middle;
        border-width: 0;
    }
}
.modalBack {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
.modalPopup {
    position: relative;
    width: var(--width-standard);
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 4em));
    width: 100%;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    transition: all 0.3s;
    display: flex;
    flex-direction: column;

    .modal.active & {
        transform: translate(-50%, -50%);
    }
    & > :first-child {
        border-radius: 0.5em 0.5em 0 0;
    }
    & > :last-child {
        border-radius: 0 0 0.5em 0.5em;
    }
}

.modalHead,
.modalFoot {
    background-color: #eee;
    line-height: 3rem;
    text-align: center;
    font-weight: bold;
    margin: 0;
    font-size: 1em;
}
.modalHead {
    order: 1;
}
.modalFoot {
    order: 3;
}
.modalBody {
    order: 2;
    background-color: #fff;
    overflow: auto;
    flex: 1 1 auto;
}
