@value mediaImage from '../figure/figure.module.css';

.droplist {
    position: absolute;
    background-color: white;
    z-index: 11;
    width: 100%;
    overflow: auto;
    line-height: 2.5;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    top: calc(3rem + 5px);
    max-height: calc(100vh - (3em + 5px));
    border-radius: 4px 4px 0 0;
    padding-block: 5px;

    .item {
        & ~ .item {
            margin-block-start: 0.1em;
        }
        &:hover {
            background-color: var(--color-main-lighter);
        }
    }
    :global(.mediaImage) {
        height: 3em;
    }
    .active {
        background-color: var(--color-main-light);
    }
}

.hotdog {
    background-color: var(--color-main-lighter);
    border-radius: 3em;
    margin: 0.25em;
    line-height: 2.5em;
    height: 2.5em;
    input {
        background-color: transparent;
        height: 100%;
        width: 100%;
        display: inline-block;
        line-height: inherit;
        padding: 0 1em;
        border-width: 0;
        outline: 0;
        font: inherit;
    }
    .header & {
        background-color: rgba(255, 255, 255, 0.7);
    }
}
