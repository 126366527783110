@value figure from '../../components/figure/figure.module.css';
@value singleImage from '../../components/figure/figure.module.css';
@value itemImage from '../../components/figure/figure.module.css';
@value list from '../../components/list/list.module.css';

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;

    &.compareSelected,
    :global(.singleImage):hover &,
    :global(.itemImage):hover &,
    :global(.figure):hover & {
        opacity: 1;
    }

    @media only screen and (max-width: 750px) {
        :global(.list) & {
            opacity: 1;
        }
    }
    @media (hover: none) {
        :global(.list) & {
            opacity: 1;
        }
    }
}

.overlayBack {
    :global(.singleImage):hover &,
    :global(.itemImage):hover & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
    }

    @media only screen and (max-width: 750px) {
        :global(.list) & {
            display: none;
        }
    }
    @media (hover: none) {
        :global(.list) & {
            display: none;
        }
    }
}

.floater {
    position: absolute;
    --floater-offset: 0;

    &.offsetNone {
        --floater-offset: 0;
    }
    &.offsetSmall {
        --floater-offset: 5px;
    }
    &.offsetLarge {
        --floater-offset: 15px;
    }
    &.top {
        top: var(--floater-offset);
    }
    &.right {
        right: var(--floater-offset);
    }
    &.bottom {
        bottom: var(--floater-offset);
    }
    &.left {
        left: var(--floater-offset);
    }
}

.compareSelectButton {
    position: relative;
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    pointer-events: initial;
    padding: 0;
    margin: 0;
    border-width: 0;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s;
    appearance: none;
    &:hover {
        transform: scale(1.2, 1.2);
    }
    &::before {
        background-color: white;
        font-size: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }
}

.compareSelectButton::before,
.compareSelected .compareSelectButton::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    border-width: 0;
}

.compareSelected {
    .compareSelectButton::after {
        background-color: var(--color-main-light);
        background-image: url('../../img/checkmark.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 160%;
        font-size: 16px;
    }
}
