*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --border-size: 0.2em;
    --header-height: 3em;
    --color-main: #72c6ff;
    --color-main-dark: #397f6c;
    --color-main-medium: #77c4af;
    --color-main-light: #c8e6de;
    --color-main-lighter: #eff8f5;
    --color-cast: #cfcafd;
    --color-cast-dark: #3a3d80;
    --color-cast-light: #f0eeff;
    --color-crew: #72fff6;
    --color-crew-dark: #3a8078;
    --color-crew-light: #d4fffe;
    --color-cancel: #ff7e7e;
    --color-commit: #57d81d;
    --color-neutral: #aaa;
    --color-neutral-light: #e7ebe8;

    --color-movie-light: #cfe8f2;
    --color-tv-light: #dcc6e2;

    --width-standard: 1000px;
}

body {
    margin: 0;
    /*overflow: hidden;*/
    font-family: 'Arial Narrow', arial, sans-serif;
    font-size: 100%;
    background-color: #f4f9f8;

    > iframe {
        display: none;
    }
}

dt {
    margin-top: 0.5em;
    font-weight: bold;
}

dd {
    margin: 10px;
}

p {
    line-height: 1.2em;
}

a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
}
h2 {
    clear: both;
}
img {
    vertical-align: text-bottom;
}
/* 
.collapsed {
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

.well,
.swell {
    background-color: rgba(0, 0, 0, 0.12);
    padding: 10px;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.swell {
    padding: initial;
}

.lined {
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.centered-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fill-child {
    height: 100%;
    width: 100%;
}
.fill-height {
    height: 100%;
}
.fill-width {
    width: 100%;
}

code {
    display: block;
    white-space: pre-wrap;
    padding: 10px;
    background-color: #efe;
    border-radius: 4px;
} */
/* 
.page {
    flex: 1 1 100%;
    overflow: auto;
    padding-bottom: 10em;

    max-width: var(--width-standard);
    margin: 1em auto 0;
    box-shadow: 0 0 3px rgb(0 0 0 / 20%);
    border-radius: 5px 5px 0 0;
    background-color: white;
    position: relative;
} */
/* 
.header-btn,
.btn-group .btn,
.btn-group a,
.btn-group input {
    line-height: 3rem;
    text-align: center;
    padding: 0 1em;
    font-size: 0.8em;
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    border-width: 0;
    border-radius: 0;
    background-color: transparent;
    display: block;
}
.header-btn .btn-icon,
[data-role='header'] a .btn-icon,
[data-role='footer'] a .btn-icon,
.btn-group .btn .btn-icon,
.btn-group a .btn-icon,
.btn-group input .btn-icon {
    width: 1em;
    height: 1em;
    display: block;
    font-size: 2em;
    line-height: 2rem;
    font-style: normal;
}
.header-btn .btn-description,
[data-role='header'] a .btn-description,
[data-role='footer'] a .btn-description,
.btn-group .btn .btn-description,
.btn-group a .btn-description,
.btn-group input .btn-description {
    display: block;
    line-height: 1rem;
    font-size: 0.75em;
}
.header-btn.active,
[data-role='header'] a.active,
[data-role='footer'] a.active,
.btn-group .active.btn,
.btn-group a.active,
.btn-group input.active,
.header-btn:hover,
[data-role='header'] a:hover,
[data-role='footer'] a:hover,
.btn-group .btn:hover,
.btn-group a:hover,
.btn-group input:hover {
    background-color: white;
}

[data-role='page']:not(.active) {
    display: none;
}

[data-role='page'] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

[data-role='header'],
[data-role='footer'] {
    height: 3rem;
    background-color: #eee;
    flex: none;
}

[data-role='header'] {
    position: relative;
    order: 1;
}

[data-role='footer'] {
    order: 3;
}
[data-role='footer'] li {
    flex: 1 1 auto;
}

[data-role='main'] {
    order: 2;
    height: 100%;
    flex: 1 1 auto;
    overflow: auto;
}
[data-role='main'] h2 {
    font-size: 1.1em;
}

[data-role='header']:first-child + [data-role='main'],
[data-role='footer']:first-child + [data-role='main'] {
    height: calc(100% - 3rem);
}

[data-role='header'] + [data-role='footer'] + [data-role='main'] {
    height: calc(100% - 6rem);
} */
/* 
.navbar {
    background-color: var(--color-main-light);
    position: sticky;
    top: 0;
    z-index: 12;
}
.navbar ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: var(--header-height);
}
.navbar a {
    padding: 0 0.5em;
}
.navbar li {
    position: relative;
}

.header-list {
    display: flex;
    justify-content: center;
    line-height: var(--header-height);
    gap: 0.5em;
    border-bottom: 2px solid var(--color-main-lighter);
}
.header-list a {
    padding: 0 0.5em;
}
.header-list a:hover {
    background-color: var(--color-main-lighter);
}
.header-list .active {
    position: relative;
}
.header-list .active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--color-main-light);
} */
/* 
.btn {
    display: inline-block;
    box-shadow: 0 0 0 transparent;
    height: 2em;
    font-size: inherit;
    font-family: inherit;
    background-color: #ddd;
    line-height: 2em;
    padding: 0 0.5em;
    margin: 0;
    color: inherit;
    border-radius: 4px;
    border-width: 0;
    vertical-align: baseline;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
}
.btn:hover,
.btn:active {
    background-color: #efefef;
    color: inherit;
}
.btn-full {
    width: 100%;
    text-align: center;
}
.btn-text {
    background-color: transparent;
    padding: 0;
}

a.btn {
    text-decoration: none;
}
a.btn:hover {
    text-decoration: none;
} */
/* 
.display-flex,
.flex-parent {
    display: flex;
}

.flex-stretch,
.flex-child {
    flex: 1 1 auto;
}
.flex-none {
    flex: none;
}
.flex-vertical {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-align-center {
    align-items: center;
}
.flex-justify-center {
    align-items: center;
} */
/* 
.overflow-x-auto {
    overflow-x: auto;
    width: 100%;
}

.space-window,
.modal,
.drawer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 13;
}
.space-window.active,
.active.modal,
.active.drawer {
    opacity: 1;
    pointer-events: initial;
}

.back-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 4em));
    width: 100%;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
}
.modal.active .modal-content {
    transform: translate(-50%, -50%);
}
.modal-content > :first-child {
    border-radius: 0.5em 0.5em 0 0;
}
.modal-content > :last-child {
    border-radius: 0 0 0.5em 0.5em;
}
.modal-head,
.modal-foot {
    background-color: #eee;
    line-height: 3rem;
    text-align: center;
    font-weight: bold;
    margin: 0;
    font-size: 1em;
}
.modal-head {
    order: 1;
}
.modal-foot {
    order: 3;
}
.modal-body {
    order: 2;
    background-color: #fff;
    overflow: auto;
    flex: 1 1 auto;
}

.drawer-content-left,
.drawer-content-right,
.drawer-content-top,
.drawer-content-bottom,
.drawer-content {
    position: absolute;
    transition: all 0.3s;
}
.drawer-content-left {
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
}
.drawer.active .drawer-content-left {
    transform: translateX(0);
}
.drawer-content-right {
    top: 0;
    right: 0;
    height: 100%;
    transform: translateX(100%);
}
.drawer.active .drawer-content-right {
    transform: translateX(0);
}
.drawer-content-top {
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
}
.drawer.active .drawer-content-top {
    transform: translateY(0);
}
.drawer-content-bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
}
.drawer.active .drawer-content-bottom {
    transform: translateY(0);
}
.drawer-head {
    background-color: #eee;
    line-height: 3rem;
    text-align: center;
    font-weight: bold;
    margin: 0;
    font-size: 1.2em;
}
.drawer-body {
    height: calc(100% - 3rem);
    background-color: #fff;
    overflow: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    text-align: left;
    padding: 0.2em 0.5em;
    vertical-align: top;
}

.table.striped tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.1);
}

.table.lined tbody tr,
.table.lined-horizontal tbody tr {
    border-top: 1px dotted #ccc;
}

.table.lined td:not(:first-child),
.table.lined th:not(:first-child),
.table.lined-vertical td:not(:first-child),
.table.lined-vertical th:not(:first-child) {
    border-left: 1px dotted #ccc;
}

.table.lined,
.table.lined-outline {
    border: 1px solid black;
}

.table tr:hover th,
.table tr:hover td {
    background-color: rgba(0, 0, 255, 0.1);
}

@media screen and (max-width: 800px) {
    [data-role='main'] h2,
    [data-role='main'] p {
        padding-left: 10px;
        padding-right: 10px;
    }
}

[data-role='jump'] {
    cursor: pointer;
}
[data-role='main'] {
    padding-bottom: 30vh;
}

.svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5em;
    vertical-align: middle;
    pointer-events: none;
} */
/* 
.header {
    height: var(--header-height);
    line-height: var(--header-height);
    background-color: var(--color-main-light);
}
.header h1 {
    font-size: 1em;
    margin: 0;
    padding: 0 0.5em;
}
.header .container {
    padding: 0 0.5em;
}

.header .nav {
    line-height: var(--header-height);
}
.nav ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.navbar a,
.nav a {
    display: block;
}
.header .nav a {
    padding: 0 0.5em;
    position: relative;
    height: 100%;
    width: 2.5em;
}
.nav .svg-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header li {
    position: relative;
} */
/* 
.crumbs li:not(:first-child)::before {
    content: ' / ';
    display: inline-block;
    margin: 0 0.2em;
}
.crumbs a {
    display: inline-block;
    padding: 0;
} */
/* 
.btnbar {
    height: var(--header-height);
    background-color: var(--color-main-light);
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-main-dark {
    background-color: var(--color-main-dark);
    color: white;
}
.back-main-light {
    background-color: var(--color-main-light);
    color: black;
}
.back-main-lighter {
    background-color: var(--color-main-lighter);
    color: black;
} */

/*.search-box {
   height: var(--header-height);
   background-color: white;
 }*/
/*.hotdog {
    background-color: var(--color-main-lighter);
    border-radius: 3em;
    margin: 0.25em;
    line-height: 2.5em;
    height: 2.5em;
}
.hotdog input {
    background-color: transparent;
    height: 100%;
    width: 100%;
    display: inline-block;
    line-height: inherit;
    padding: 0 1em;
    border-width: 0;
    outline: 0;
    font: inherit;
}
.header .hotdog {
    background-color: rgba(255, 255, 255, 0.7);
} */

/* .choice-button {
    position: absolute;
    bottom: 0.2em;
    right: 0.2em;
}
.choice-button button {
    background-color: white;
    border-width: 0;
    padding: 0.2em;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
}
.choice-button polygon {
    display: none;
}
.choice-button.chosen polygon {
    display: initial;
} */

/* .section:not(:first-child) {
    margin: 2em 0 0;
    clear: both;
}
.section-compare {
    margin: 1em 0 0;
}
.section-compare .section {
    margin: 0;
}

.section-person .list-item {
    background-color: var(--color-main-light);
}
.section-movie .list-item {
    background-color: var(--color-movie-light);
}
.section-tv .list-item {
    background-color: var(--color-tv-light);
}

.section h2 {
    font-size: 1.3em;
} */
/* 
.list {
    display: grid;
    gap: 15px;
    grid-auto-columns: 9em;
    width: 100%;
    overflow: auto;
    list-style-type: none;
    margin: 0;
    padding: 1em;
    position: relative;
}
.list:empty {
    padding: 0;
}
.fullsize .list {
    overflow: initial;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}
.fullsize .list-item {
    width: 9em;
}
.compare-options .list-person {
    grid-auto-columns: 6em;
}
.list-item {
    grid-row: 1;
    box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.1);
    border-radius: 0.4em;
    overflow: hidden;
}
.list-item.selected {
    outline: 4px solid rgba(0, 200, 100, 0.5);
}
.list-season .badge,
.list-episode .badge {
    right: 0.5rem;
    top: initial;
    bottom: 0.5rem;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);
}

.compare-options {
    min-height: 250px;
}

.item-jobs,
.item-name,
.item-title,
.item-year {
    font-size: 0.8rem;
    white-space: nowrap;
    position: relative;
    line-height: 1rem;
    height: 1rem;
}
.item-title {
    font-weight: bold;
}
.item-description {
    margin: 0.4em 0.5em;
    font-weight: normal;
    position: relative;
    overflow: hidden;
}
.single-image {
    float: right;
    width: 25vw;
    max-width: 200px;
    margin: 0 0 1em 1em;
    position: relative;

    border-radius: 5px;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.single-name,
.single-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-block-end: 1rem;
}
.single-property {
    margin: 0.5em 0;
    &.figure-gallery-section > .title {
        display: flex;
        justify-content: space-between;
        gap: 1em;
        border-width: 0;
        width: 100%;
        text-align: left;
        cursor: pointer;
    }
}
.single-description strong {
    display: block;
    font-size: 0.8em;
    color: var(--color-neutral);
    font-weight: normal;
}

.item-jobs {
    display: flex;
    overflow: hidden;
}
.item-job:not(:first-child)::before {
    content: ', ';
}
.movie-info,
.person-info,
.tv-info,
.season-info,
.episode-info {
    padding-top: 1em;
    position: relative;
    z-index: 1;
}
.videos {
    position: relative;
    z-index: 1;
}

.movie-collection .list {
    grid-auto-columns: 5em;
}
.movie-collection .item-description {
    display: none;
}

.comma-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
}
.comma-list a,
.comma-list span,
.comma-item {
    cursor: pointer;
    font-weight: normal;
    font-size: 0.75em;
    padding: 0.2rem 0.5rem 0.175rem;
    background-color: var(--color-main-light);
    border-radius: 0.2rem;
} */
/* 
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
}
.overlay.compare-selected,
.single-image:hover .overlay,
.figure:hover .overlay {
    opacity: 1;
}

.figure:hover .overlay-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

.figure-overlay {
    pointer-events: none;
}

.floater {
    position: absolute;
    --floater-offset: 0;
}
.floater.offset-none {
    --floater-offset: 0;
}
.floater.offset-small {
    --floater-offset: 5px;
}
.floater.offset-large {
    --floater-offset: 15px;
}
.floater.top {
    top: var(--floater-offset);
}
.floater.right {
    right: var(--floater-offset);
}
.floater.bottom {
    bottom: var(--floater-offset);
}
.floater.left {
    left: var(--floater-offset);
} */
/* 
.compare-select-button {
    position: relative;
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    pointer-events: initial;
    padding: 0;
    margin: 0;
    border-width: 0;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s;
    appearance: none;
}
.compare-select-button:hover {
    transform: scale(1.2, 1.2);
}
.compare-select-button::before,
.compare-selected .compare-select-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    border-width: 0;
}
.compare-select-button::before {
    background-color: white;
    font-size: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.compare-selected .compare-select-button::after {
    background-color: var(--color-main-light);
    background-image: url('img/checkmark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 160%;
    font-size: 16px;
} */
/* @media only screen and (max-width: 750px) {
    .list .overlay-back {
        display: none;
    }
    .list .overlay {
        opacity: 1;
    }
}
@media (hover: none) {
    .list .overlay-back {
        display: none;
    }
    .list .overlay {
        opacity: 1;
    }
} */

/* .people-compare .list-person,
.movie-compare .list-movie,
.tv-compare .list-tv {
    grid-auto-columns: 6em;
}
.people-compare .list-person .item-image,
.movie-compare .list-movie .item-image,
.tv-compare .list-tv .item-image {
    aspect-ratio: 1 / 1.5;
} */

/* .tv-credits-list {
    .badge {
        bottom: 0.5em;
        right: 0.5em;
        top: initial;
    }
} */

/* .modal-fullimage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}
.modal-fullimage img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 2em);
    max-height: calc(100% - 2em);
    object-fit: contain;
    pointer-events: none;
} */

/* .companies {
    display: flex;
    overflow: auto;
}
.company {
    padding: 0.5em;
}
.company img {
    height: 60px;
    width: 60px;
    object-fit: contain;
} */

/* .compare-button polygon {
    display: none;
}
.compare-button.active polygon {
    display: initial;
}
.header .compare-button.active {
    background-color: transparent;
}

.tablist {
    display: flex;
    line-height: var(--header-height);
}
.tab {
    flex: 1 1 auto;
    text-align: center;
    background-color: var(--color-main-lighter);
    position: relative;
}
.tab.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--color-main-dark);
}

.noclick {
    pointer-events: none;
}
.canclick {
    cursor: pointer;
    pointer-events: initial;
} */

.loading {
    position: relative;
}
.loading::after {
    content: '';
    background-image: url(./img/reload.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2em;
    height: 2em;
    animation: rotate 2s linear infinite;
}
.loading::before {
    content: '';
    background-color: rgba(200, 230, 222, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.marquee {
    /* position:absolute;
   left:0; */
    transition: all 3s linear;
    transform: translateX(0);
    white-space: nowrap;
    display: inline-block;
}
@container (min-width: 130px) {
    .marquee-active .marquee {
        left: 100%;
        transform: translateX(-100%);
    }
}

.badge {
    position: absolute;
    top: 0.5rem;
    right: 0;
    line-height: 1;
    font-size: 0.6em;
    padding: 0.2em 0.5em;
    background-color: white;
    border-radius: 0.5em;
    vertical-align: text-bottom;
}

@supports (-webkit-overflow-scrolling: touch) {
    .thumbs,
    .profiles,
    .list {
        overflow-y: visible;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    [data-role='main'] {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

/* .container {
    clear: both;
}

@media (min-width: 1000px) {
    .container {
        max-width: var(--width-standard);
        margin-inline: auto;
        padding-inline: 1em;
    }
}
@media (max-width: 999px) {
    .container {
        padding-inline: 0.5em;
    }
    .page {
        margin-inline: 0.5em;
    }
}
.navbar .container {
    padding-inline: 0;
} */

.grid {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
}
@media (min-width: 0) {
    .col-xs-1 {
        grid-column-end: span 1;
    }
    .col-xs-2 {
        grid-column-end: span 2;
    }
    .col-xs-3 {
        grid-column-end: span 3;
    }
    .col-xs-4 {
        grid-column-end: span 4;
    }
    .col-xs-5 {
        grid-column-end: span 5;
    }
    .col-xs-6 {
        grid-column-end: span 6;
    }
    .col-xs-7 {
        grid-column-end: span 7;
    }
    .col-xs-8 {
        grid-column-end: span 8;
    }
    .col-xs-9 {
        grid-column-end: span 9;
    }
    .col-xs-10 {
        grid-column-end: span 10;
    }
    .col-xs-11 {
        grid-column-end: span 11;
    }
    .col-xs-12 {
        grid-column-end: span 12;
    }
}
@media (min-width: 500px) {
    .col-sm-1 {
        grid-column-end: span 1;
    }
    .col-sm-2 {
        grid-column-end: span 2;
    }
    .col-sm-3 {
        grid-column-end: span 3;
    }
    .col-sm-4 {
        grid-column-end: span 4;
    }
    .col-sm-5 {
        grid-column-end: span 5;
    }
    .col-sm-6 {
        grid-column-end: span 6;
    }
    .col-sm-7 {
        grid-column-end: span 7;
    }
    .col-sm-8 {
        grid-column-end: span 8;
    }
    .col-sm-9 {
        grid-column-end: span 9;
    }
    .col-sm-10 {
        grid-column-end: span 10;
    }
    .col-sm-11 {
        grid-column-end: span 11;
    }
    .col-sm-12 {
        grid-column-end: span 12;
    }
}
@media (min-width: 900px) {
    .col-md-1 {
        grid-column-end: span 1;
    }
    .col-md-2 {
        grid-column-end: span 2;
    }
    .col-md-3 {
        grid-column-end: span 3;
    }
    .col-md-4 {
        grid-column-end: span 4;
    }
    .col-md-5 {
        grid-column-end: span 5;
    }
    .col-md-6 {
        grid-column-end: span 6;
    }
    .col-md-7 {
        grid-column-end: span 7;
    }
    .col-md-8 {
        grid-column-end: span 8;
    }
    .col-md-9 {
        grid-column-end: span 9;
    }
    .col-md-10 {
        grid-column-end: span 10;
    }
    .col-md-11 {
        grid-column-end: span 11;
    }
    .col-md-12 {
        grid-column-end: span 12;
    }
}
@media (min-width: 1200px) {
    .col-lg-1 {
        grid-column-end: span 1;
    }
    .col-lg-2 {
        grid-column-end: span 2;
    }
    .col-lg-3 {
        grid-column-end: span 3;
    }
    .col-lg-4 {
        grid-column-end: span 4;
    }
    .col-lg-5 {
        grid-column-end: span 5;
    }
    .col-lg-6 {
        grid-column-end: span 6;
    }
    .col-lg-7 {
        grid-column-end: span 7;
    }
    .col-lg-8 {
        grid-column-end: span 8;
    }
    .col-lg-9 {
        grid-column-end: span 9;
    }
    .col-lg-10 {
        grid-column-end: span 10;
    }
    .col-lg-11 {
        grid-column-end: span 11;
    }
    .col-lg-12 {
        grid-column-end: span 12;
    }
}
@media (min-width: 1600px) {
    .col-xl-1 {
        grid-column-end: span 1;
    }
    .col-xl-2 {
        grid-column-end: span 2;
    }
    .col-xl-3 {
        grid-column-end: span 3;
    }
    .col-xl-4 {
        grid-column-end: span 4;
    }
    .col-xl-5 {
        grid-column-end: span 5;
    }
    .col-xl-6 {
        grid-column-end: span 6;
    }
    .col-xl-7 {
        grid-column-end: span 7;
    }
    .col-xl-8 {
        grid-column-end: span 8;
    }
    .col-xl-9 {
        grid-column-end: span 9;
    }
    .col-xl-10 {
        grid-column-end: span 10;
    }
    .col-xl-11 {
        grid-column-end: span 11;
    }
    .col-xl-12 {
        grid-column-end: span 12;
    }
}
/* 
.droplist {
    position: absolute;
    background-color: white;
    z-index: 11;
    width: 100%;
    overflow: auto;
    line-height: 2.5;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    top: calc(3rem + 5px);
    max-height: calc(100vh - (3em + 5px));
    border-radius: 4px 4px 0 0;
    padding-block: 5px;
}
.droplist-item ~ .droplist-item {
    margin-block-start: 0.1em;
}
.droplist .media-image {
    height: 3em;
}
.droplist-item:hover {
    background-color: var(--color-main-lighter);
}
.droplist .active {
    background-color: var(--color-main-light);
} */
