@value list from '../../components/list/list.module.css';

.comparePage {
    position: relative;
}

.peopleCompare,
.movieCompare,
.tvCompare {
    :global(.list) {
        grid-auto-columns: 6em;
    }
}
/* 
.people-compare .list-person,
.movie-compare .list-movie,
.tv-compare .list-tv {
    grid-auto-columns: 6em;
}
.people-compare .list-person .item-image,
.movie-compare .list-movie .item-image,
.tv-compare .list-tv .item-image {
    aspect-ratio: 1 / 1.5;
}
*/
