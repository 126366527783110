@value itemImage from '../../components/figure/figure.module.css';
@value movieCollection from '../../components/page/page.module.css';
@value itemDescription from '../../components/figure/figure.module.css';

/* .section-compare {
    margin: 1em 0 0;
}
.section-compare .section {
    margin: 0;
} */

.listItem {
    grid-row: 1;
    box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.1);
    border-radius: 0.4em;
    overflow: hidden;

    a {
        vertical-align: middle;
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    &.selected {
        outline: 4px solid rgba(0, 200, 100, 0.5);
    }

    .tv & {
        background-color: var(--color-tv-light);
    }
    .movie & {
        background-color: var(--color-movie-light);
    }
    .person & {
        background-color: var(--color-main-light);
    }
    .fullsize & {
        width: 9em;
    }

    &:hover :global(.itemImage) img {
        transform: scale(1.1);
    }

    :global(.movieCollection) & {
        height: 8em;
        aspect-ratio: 2/3;
    }
}

.list {
    display: grid;
    gap: 15px;
    grid-auto-columns: 9em;
    width: 100%;
    overflow: auto;
    list-style-type: none;
    margin: 0;
    padding-inline: 1em;
    position: relative;
    &:empty {
        padding: 0;
    }
    .fullsize & {
        overflow: initial;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
    }
    :global(.movieCollection) & {
        grid-auto-columns: 5.333em;
    }
    &.movie :global(.itemImage) {
        aspect-ratio: 2/3;
    }
    &.person :global(.itemImage) {
        aspect-ratio: 2/2.65;
    }

    &.compareList {
        display: inline-grid;
        flex: none;
        width: initial;
        grid-auto-columns: 6em;
        gap: 0;
        border-radius: 0.4em;
        overflow: hidden;
        padding: 0;

        .listItem {
            border-radius: 0;
            box-shadow: none;
        }
        :global(.itemImage) {
            aspect-ratio: 2/3;
        }
    }
}

:global(.movieCollection) :global(.itemDescription) {
    display: none;
}

.listEpisode {
    :global(.itemImage) {
        aspect-ratio: 144 / 101;
    }
}

.compareRow {
    display: flex;
    width: 100%;
    overflow-x: auto;
    gap: 1em;
    padding-inline: 1em;
}

.tvCreditsList,
.listSeason,
.listEpisode {
    position: relative;
}

.singleName,
.singleTitle {
    font-size: 1.5em;
    font-weight: bold;
    margin-block-end: 1rem;
}
.singleProperty {
    margin: 0.5em 0;
    &.figure-gallery-section > .title {
        display: flex;
        justify-content: space-between;
        gap: 1em;
        border-width: 0;
        width: 100%;
        text-align: left;
        cursor: pointer;
    }
}
.singleDescription strong {
    display: block;
    font-size: 0.8em;
    color: var(--color-neutral);
    font-weight: normal;
}

.itemJobs {
    display: flex;
    overflow: hidden;
}
.itemJob:not(:first-child)::before {
    content: ', ';
}
.movieInfo,
.personInfo,
.tv-info,
.seasonInfo,
.episodeInfo {
    padding-block-start: 1em;
    position: relative;
    z-index: 1;
}
.videos {
    position: relative;
    z-index: 1;
}

.commaList {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
}
.commaList a,
.commaList span,
.commaItem {
    cursor: pointer;
    font-weight: normal;
    font-size: 0.75em;
    padding: 0.2rem 0.5rem 0.175rem;
    background-color: var(--color-main-light);
    border-radius: 0.2rem;
    border-width: 0;
}

.spacedList {
    display: flex;
    gap: 1em;
}

.listHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flex {
        flex: none;
        display: flex;
        gap: 0.5em;
    }
}

.thumbs {
    display: flex;
    overflow: auto;
    list-style-type: none;
    margin-block: 1em;
    padding-inline-start: 1em;
    clear: both;
    overflow: auto;
    gap: 5px;
    width: 100%;

    .thumb {
        display: block;
        flex: 0 0 auto;

        img {
            height: 6em;
        }
    }
}

.crumbs {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    a {
        display: inline-block;
        padding: 0;
    }
    li:not(:first-child)::before {
        content: ' / ';
        display: inline-block;
        margin: 0 0.2em;
    }
}

.tabbedList {
    width: 100%;
    .tabs {
        display: flex;
        border-block-width: 2px;
        border-inline-width: 0;
        border-style: solid;
        border-color: var(--color-main-lighter);
        width: 100%;
        overflow-x: auto;
        padding-inline: 1em;

        button {
            padding: 1em;
            border: 0;
            cursor: pointer;
            font-weight: bold;
            background-color: transparent;
            &:hover {
                background-color: var(--color-main-lighter);
            }
            &.active {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background-color: var(--color-main-light);
                }
            }
        }
    }
    .contents {
        margin-top: 0.5em;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                margin: 0;
                padding: 0;
            }
        }
        .content ul {
            padding-inline: 1em;
        }
    }
    .hidden {
        display: none;
    }
}

.companies {
    display: flex;
    overflow: auto;
    padding-inline-start: 1em;
    gap: 0.4em;
    .company {
        flex: none;
        img {
            height: 8em;
            width: 8em;
            object-fit: contain;
        }
    }
    a {
        display: block;
        &:has(.companyCircle) {
            height: 8em;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background-color: var(--color-main-light);
            display: flex;
            overflow: hidden;
            align-items: center;
            white-space: normal;
            text-align: center;
            justify-content: center;
            padding: 0.5em;
            text-wrap: balance;
        }
    }
}
